.navbar__page {
    display: none;
    position: fixed;
    right: 20px;
    top: calc(50% - (84px / 2));
    z-index: 4;
    @media (min-width: 768px) {
        display: block;
    }
}
.toc {
    list-style: none;
    margin: 0;
    padding: 0;
    &__item {
        display: block;
        margin: 0 0 5px;
        &__link {
            border: 1px solid @medium-gray;
            border-radius: 50%;
            display: block;
            height: 16px;
            padding: 0;
            position: relative;
            text-indent: -9999px;
            width: 16px;
            &.active {
                &::after {
                    background-color: @primary-color;
                    border-radius: 50%;
                    content: "";
                    display: block;
                    height: 10px;
                    left: 2px;
                    position: absolute;
                    top: 2px;
                    width: 10px;
                }
            }
        }
    }
}
