.font-face(@font-path:'../fonts/'; @file-name; @font-family; @font-weight; @font-style;) {
    @font-face {
        font-display: fallback;
        font-family: @font-family;
        font-style: @font-style;
        font-weight: @font-weight;
        src: url('@{font-path}@{file-name}.woff2') format('woff2'), url('@{font-path}@{file-name}.woff') format('woff');
    }
}

.font-face(
    @file-name:   'montserrat-regular-webfont';
    @font-family: 'Montserrat';
    @font-style:  normal;
    @font-weight: 400;
);
.font-face(
    @file-name:   'montserrat-medium-webfont';
    @font-family: 'Montserrat';
    @font-style:  normal;
    @font-weight: 500;
);
.font-face(
    @file-name:   'montserrat-semibold-webfont';
    @font-family: 'Montserrat';
    @font-style:  normal;
    @font-weight: 600;
);
