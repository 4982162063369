.navbar--atreus {
    padding: 0;
}
.nav-link {
    &--atreus {
        border: 1px solid transparent;
        color: @white;
        letter-spacing: 0.19px;
        margin-left: 8px;
        padding: 4px 18px;
        transition: all 0.15s;
        &:hover {
            border-color: @white;
            color: @white;
        }
        &:focus,
        &:active {
            border-color: @white;
            color: @white;
        }
        .active & {
            border-color: @white;
        }
    }
    &--triitfree {
        background-color: @primary-color;
        border: 1px solid @primary-color;
        color: @black;
        transition: all 0.15s;
        &:focus,
        &:active,
        &:hover {
            background-color: fade(@primary-color, 75%);
            border-color: @primary-color;
            color: @black;
        }
    }
}
.toggler--atreus {
    &:focus {
        color: inherit;
        outline: none;
    }
    &.navbar-toggler {
        border-color: fade(@white, 70%);
        color: @white;
    }
    .navbar-toggler-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 0.7)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
    }
}
