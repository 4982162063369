.section--pricing {
    margin-left: auto;
    margin-right: auto;
    @media (max-width: 1299px) {
        max-width: 840px;
    }
    .col-md-4 {
        margin-bottom: 20px;
    }
}
.pricing {
    background-color: @white;
    border: 0;
    border-radius: 0;
    box-shadow: 0 0 38px fade(@black, 8%);
    display: flex;
    flex-direction: column;
    height: 100%;
    line-height: 22px;
    transition: ease 0.15s;
    &:hover {
        box-shadow: 0 0 38px fade(@black, 15%);
    }
    &--enterprise {
        padding: 20px 30px 44px;
    }
    .btn--atreus {
        font-weight: 400;
        width: 120px;
    }
}
.pricing__header__title {
    font-size: 24px;
    line-height: 26px;
    margin-bottom: 12px;
    margin-top: 9px;
}
.pricing__header {
    padding: 20px 30px 22px;
    flex: 1;
    p {
        margin-bottom: 12px;
    }
    .pricing--enterprise & {
        flex: 1;
        padding: 0 0 20px;
    }
    &--gradient {
        background: @white;
        background: linear-gradient(to bottom, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 75%, rgb(255, 255, 255) 75%, rgba(242, 242, 242, 0.65) 100%);
    }
    &__img {
        float: right;
    }
    .btn--default {
        float: right;
        width: 120px;
    }
}
.pricing__plan__period {
    color: fade(@black, 80%);
    display: block;
    font-size: 12px;
}
.pricing__list {
    font-size: 12px;
    margin: 0 0 22px;
    padding-left: 12px;
}
.pricing__plan {
    align-content: center;
    align-items: center;
    border-bottom: 6px solid #f8f8f8;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 1fr 1fr;
    padding: 20px 30px;
    @media (min-width: 768px) and (max-width: 991px) {
        grid-template-columns: 1fr;
    }
    &:last-child {
        border-width: 0 0 16px;
    }
}
.pricing__plan__order {
    text-align: right;
    @media (min-width: 768px) and (max-width: 991px) {
        text-align: center;
    }
}
.pricing__price {
    color: fade(@black, 80%);
    display: block;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
}
.pricing__period {
    display: block;
    text-align: center;
}
.pricing__footer {
    text-align: center;
}
.checkout {
    align-items: center;
    color: @white;
    display: grid;
    font-size: 11px;
    grid-gap: 30px;
    grid-template-columns: 1fr;
    justify-items: center;
    line-height: 16px;
    margin: 25px auto 0;
    max-width: 625px;
    @media (min-width: 768px) {
        grid-template-columns: 1fr 1fr 1fr;
    }
}
