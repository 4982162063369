&.template-404 {
    color: #fff;
    .main {
        align-items: center;
        display: flex;
        justify-content: center;
        padding: 40px 0 40px;
        @media (min-width: 768px) {
            padding: 80px 0 120px;
        }
    }
    h1 {
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0.38px;
        margin-bottom: 5rem;
        opacity: 0.5;
    }
    .icon__404 {
        margin-bottom: 40px;
    }
    .btn--default {
        border-color: #fff;
        color: #fff;
        &:hover {
            background-color: #e2d002;
            border-color: #e2d002;
            color: @secondary-color;
        }
    }
    .footer__request {
        display: none;
    }
}
