.cards {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: 1fr;
    @media (min-width: 768px) {
        grid-template-columns: 1fr 1fr;
    }
}
.box-card {
    background-color: @white;
    border: none;
    box-shadow: 0 0 38px fade(@black, 8%);
    height: 100%;
    padding: 45px 30px 55px;
    position: relative;
    transition: 0.4s all;
    img {
        display: block;
        margin: 0 auto 45px;
    }
    h3 {
        font-size: 24px;
        letter-spacing: 0.11px;
        margin-bottom: 19px;
    }
}
.auction__readmore {
    align-items: flex-end;
    bottom: 0;
    color: @black;
    display: flex;
    justify-content: center;
    left: 0;
    padding: 0 25px 45px;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    z-index: 1;
    &:hover {
        background-color: fade(@primary-color, 5%);
        color: @black;
        text-decoration: underline;
        &::after {
            border-color: @primary-color;
            border-style: solid;
            border-width: 0 6px 6px 0;
            bottom: 0;
            content: "";
            height: 48px;
            position: absolute;
            right: 0;
            width: 48px;
        }
        &::before {
            border-color: #e2d002;
            border-style: solid;
            border-width: 0 0 6px 6px;
            bottom: 0;
            content: "";
            height: 48px;
            left: 0;
            position: absolute;
            width: 48px;
        }
    }
}
