.borderless {
    color: @white;
    letter-spacing: 1px;
    th {
        border-color: #284e53;
        border-style: solid;
        border-width: 0 0 0 2px;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        padding: 12px 5px;
        @media (min-width: 768px) {
            font-size: 18px;
            line-height: 24px;
            padding: 18px 0;
        }
        &:first-child {
            border-color: transparent;
        }
        &.borderless--title {
            font-size: 16px;
            font-weight: 600;
            letter-spacing: 0.41px;
            line-height: 48px;
            @media (min-width: 768px) {
                font-size: 36px;
            }
        }
        &.borderless--header {
            border-bottom: 0;
            padding-bottom: 15px;
            vertical-align: top;
        }
    }
    td {
        border-color: #284e53;
        border-style: solid;
        border-width: 0 0 0 2px;
        padding: 12px 0;
        @media (min-width: 768px) {
            padding: 18px 0;
        }
    }
    .head-img {
        height: auto;
        margin-bottom: 38px;
    }
}
