.breadcrumb {
    &--atreus {
        background-color: transparent;
        font-size: 12px;
        margin-bottom: 40px;
        padding-left: 0;
        padding-right: 0;
    }
}
.breadcrumb-item {
    & + .breadcrumb-item--atreus {
        padding-left: 0;
        &::before {
            content: ">";
            padding: 0 5px 0 8px;
        }
    }
    a {
        color: @secondary-color;
        font-weight: 500;
        &.active {
            font-weight: 400;
        }
        .template-contact-us & {
            color: @white;
        }
    }
}
