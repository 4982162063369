.btn {
    border-radius: 0;
    letter-spacing: 0.06px;
    &--default {
        border-color: fade(@black, 60%);
        color: fade(@black, 60%);
    }
    &--atreus {
        background-color: @primary-color;
        color: @black;
        font-size: 16px;
        padding: 8px 16px;
        &:hover {
            opacity: 0.8;
        }
    }
    &--request {
        border-color: @primary-color;
        color: @white;
        padding: 8px 22px 10px;
        &:hover {
            background-color: @primary-color;
            color: @black;
        }
    }
    &--send {
        padding-left: 69px;
        padding-right: 69px;
        width: auto;
    }
    &--sm {
        font-size: 14px;
    }
}
