.section--main {
    align-items: center;
    background-attachment: fixed;
    background-image: url('../img/background.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: @white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;
    padding: 130px 0;
    position: relative;
    h1 {
        color: @white;
        margin-bottom: 10px;
        @media (max-width: 767px) {
            font-size: 30px;
        }
    }
    h2 {
        color: fade(@white, 60%);
        font-size: 26px;
        margin: 0 0 47px;
        text-align: center;
        @media (min-width: 768px) {
            font-size: 36px;
        }
    }
    .section__description {
        color: fade(@white, 60%);
        font-size: 16px;
        letter-spacing: 0.16px;
        line-height: 28px;
        margin-bottom: 119px;
        text-align: center;
    }
    .container {
        max-width: 800px;
    }
}
.go__next__section {
    bottom: 40px;
    left: calc(50% - 15px);
    position: absolute;
}
.section--second {
    margin-bottom: -93px;
    h2 {
        background-color: @primary-color;
        color: #042c30;
        font-size: 36px;
        margin-bottom: -64px;
        padding: 45px 0 126px;
        text-align: center;
    }
}
.section--third {
    background: #013040;
    background: linear-gradient(to bottom, rgb(0, 44, 52) 0%, rgb(2, 52, 62) 0%, rgb(2, 47, 56) 15%, rgb(2, 27, 32) 50%, rgb(0, 25, 30) 50%, rgb(1, 17, 20) 65%, rgb(0, 15, 18) 66%, rgb(1, 16, 19) 67%, rgb(0, 5, 6) 85%, rgb(0, 0, 0) 97%, rgb(0, 1, 1) 100%);
    color: @white;
    overflow-x: auto;
    padding: 200px 0 126px;
    &::-webkit-scrollbar {
        width: 12px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #fff;
    }
}
.section--four {
    color: @black;
    font-size: 16px;
    line-height: 30px;
    padding: 80px 0 86px;
    h2 {
        font-size: 36px;
        line-height: 45px;
        margin-top: -4px;
        @media (min-width: 768px) {
            text-align: right;
        }
    }
    h3 {
        margin-top: 55px;
    }
    .row:first-child {
        margin-bottom: 40px;
    }
}
.section--contact {
    h2 {
        font-size: 36px;
        line-height: 45px;
    }
}
