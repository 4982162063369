.auction__description {
    &--first {
        margin-bottom: 63px;
    }
    &--second {
        padding-bottom: 51px;
        padding-top: 51px;
    }
    &--visual {
        background: linear-gradient(to bottom, rgb(1, 40, 48) 0%, rgb(2, 44, 53) 0%, rgb(2, 40, 48) 15%, rgb(1, 38, 46) 16%, rgb(2, 39, 47) 18%, rgb(1, 25, 30) 43%, rgb(2, 26, 31) 44%, rgb(0, 15, 18) 62%, rgb(1, 10, 12) 74%, rgb(0, 8, 10) 75%, rgb(0, 0, 0) 100%);
        color: #fff;
        padding: 48px 0;
        text-align: center;
        img {
            height: auto;
            max-width: 100%;
            width: auto;
        }
    }
}
