html {
    scroll-behavior: smooth;
}
body {
    display: flex;
    flex-direction: column;
    font-family: @global-font-family;
    font-size: 0.875rem;
    letter-spacing: 0.06px;
    line-height: 24px;
    min-height: 100vh;
    padding-top: 90px;
    &.template-homepage {
        padding-top: 0;
    }
    &.template-404,
    &.template-contact-us {
        background-image: linear-gradient(to top, #000 0%, #02343e 100%);
    }
}
h1 {
    font-size: 40px;
    font-weight: 600;
    letter-spacing: 0.18px;
    text-align: center;
    &.heading {
        margin-bottom: 44px;
    }
}
.heading__visual {
    color: #92a0a1;
    display: block;
}
h2 {
    font-size: 30px;
    font-weight: 600;
    line-height: 1.3em;
    margin: 0 0 22px;
    .rich-text &:not(:first-child) {
        margin-top: 48px;
    }
}
h3 {
    font-family: @sans-serif-fonts;
    font-size: 16px;
    font-weight: 600;
}
a {
    color: @secondary-color;
    &:active,
    &:focus {
        color: inherit;
        outline: none;
    }
    .rich-text & {
        text-decoration: underline;
    }
}
b {
    font-weight: 600;
}
strong {
    font-weight: 600;
}
ul {
    margin-bottom: 1rem;
    margin-top: 0;
    .rich-text > & {
        list-style: none;
        padding: 0;
        > li {
            margin-bottom: 10px;
            padding: 0 0 0 28px;
            position: relative;
            &::before {
                color: @primary-color;
                content: "\2022";
                font-size: 25px;
                font-weight: 600;
                left: 3px;
                position: absolute;
                top: 0;
                vertical-align: middle;
            }
            ul {
                padding-left: 42px;
                width: 100%;
                ul {
                    padding-left: 64px;
                }
            }
        }
    }
}
.list--auction-steps {
    display: grid;
    flex: 1;
    grid-gap: 75px;
    grid-template-columns: 1fr 1fr;
    list-style: none;
    margin: 0;
    max-width: 900px;
    @media (min-width: 768px) and (max-width: 991px) {
        grid-template-columns: 1fr;
    }
    img {
        float: left;
        margin: -35px 20px 10px 0;
    }
}
.hidden {
    display: none;
}
.main {
    flex: 1;
    padding-bottom: 100px;
    .template-pricing &,
    .template-auction-type &,
    .template-homepage & {
        padding-bottom: 0;
    }
    .template-contact-us & {
        background-color: fade(#02313a, 35%);
        color: @white;
    }
    .template-pricing & {
        background: linear-gradient(to bottom, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 60%, #02343e 60%, #02343e 100%);
    }
}
.rich-text {
    margin-bottom: 44px;
}
.richtext-image {
    display: block;
    height: auto;
    max-width: 100%;
    &.full-width {
        margin: 0 0 30px;
    }
    &.left {
        float: left;
        margin: 0 30px 30px 0;
    }
    &.right {
        float: right;
        margin: 0 0 30px 30px;
    }
}
