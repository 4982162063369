.form--contactus {
    margin: auto;
    max-width: 600px;
}
.form-try {
    display: grid;
    grid-template-columns: auto;
    margin: auto;
    max-width: 600px;
    position: relative;
    @media (min-width: 768px) {
        grid-template-columns: auto auto;
        grid-gap: 15px;
    }
    .btn--atreus {
        min-width: 125px;
        padding: 10px;
        @media (min-width: 768px) {
            padding: 0 5px;
        }
    }
}
.form--search {
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
    display: flex;
    margin-bottom: 40px;
}
.g-recaptcha > div {
    margin: auto;
}

// Field
label {
    font-size: 18px;
    padding-left: 12px;
}
input {
    background-color: fade(@white, 5%);
    border: 1px solid @secondary-color;
    font-size: 18px;
    padding: 9px 0 11px 12px;
    .form--contactus & {
        margin-bottom: 28px;
    }
    .form-try & {
        border-color: @white;
        color: @white;
        line-height: 33px;
        margin: 0;
        min-width: 75%;
        padding-left: 20px;
        &.invalid {
            background-color: #ff000059;
            border-color: #f00;
        }
    }
    .form--contactus & {
        border-color: @white;
        color: @white;
        width: 100%;
    }
    .form--search & {
        width: 100%;
        border: 0;
        padding: 10px 0 10px 12px;
    }
}
textarea {
    background-color: fade(@white, 5%);
    border: 1px solid @secondary-color;
    font-size: 18px;
    margin-bottom: 28px;
    padding: 8px 0 8px 12px;
    width: 100%;
    .form--contactus & {
        border-color: @white;
        color: @white;
        margin-bottom: 38px;
    }
}
button {
    .form--search & {
        &:hover {
            opacity: 0.65;
        }
    }
}
.form__help {
    color: #666;
    display: block;
    font-style: italic;
}
.fiels--country-code {
    display: none;
}
.fiels--phone {
    margin-bottom: 28px;
}
.fiels--wagtailcaptcha {
    margin-bottom: 38px;
    label {
        display: none;
    }
}
