.footer {
    background: #02343e;
    background: linear-gradient(to top, #000 0%, #02343e 100%);
    color: @white;
    padding: 35px 0;
    .template-404 &,
    .template-contact-us & {
        background: transparent;
    }
}
.footer__request {
    margin: 38px 0 80px;
    text-align: center;
}
.footer__colophon {
    @media (min-width: 768px) {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }
    &__copyright {
        color: fade(@white, 50%);
        font-size: 1rem;
        font-weight: 500;
        text-transform: uppercase;
        @media (max-width: 767px) {
            margin-bottom: 15px;
            text-align: center;
        }
    }
    &__soclink {
        text-align: center;
    }
}
.list--soclink {
    align-items: center;
    display: flex;
    justify-content: center;
    list-style: none;
    margin: 0;
    padding: 0;
}
.list__link--soclink {
    display: block;
    line-height: 1;
    opacity: 0.5;
    padding: 0 10px;
    @media (min-width: 768px) {
        padding: 0 20px;
    }
}
