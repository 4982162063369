.header--atreus {
    background-color: @secondary-color;
    padding: 0.5rem 4%;
    transition: all 0.4s;
    .template-homepage & {
        background-color: fade(#031c1d, 75%);
    }
    .template-contact-us & {
        background-color: #032228;
    }
}
